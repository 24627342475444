.hero-container {
  height: calc(100vh - 68px);
}

.hero-content-container {
  border-bottom-width: 1.5px;
  border-color: rgba(255, 255, 255, 0.16);
  border-left-width: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-top-width: 0px;
  background-color: #21212166;
}

h1 {
  font-weight: 600;
}

.about-section div {
  position: relative;
  cursor: pointer;
}

.about-section div img {
  transition: all 0.3s ease;
}

.about-section div::before {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 180px;
  padding: 10px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px 0px 0px 0px;
  background: rgba(0, 0, 0, 0.32);
  backdrop-filter: blur(6px);
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  z-index: 4;
}

.about-section img {
  transition: all 0.6s ease;
}
.about-section img:hover {
  transform: scale(1.05);
  filter: grayscale(0.5);
}

.about-one::before {
  content: "Gold Recovery";
}

.about-two::before {
  content: "Air";
}

.about-three::before {
  content: "Water";
}

.form-field:focus {
  outline: 1px solid #aa5647be;
}

/* email toast notification */

.toast {
  position: fixed;
  bottom: 60px;
  right: 80px;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999;
}

.toast.show {
  opacity: 1;
  visibility: visible;
}

.toast.success {
  background-color: #4caf50;
}

.toast.error {
  background-color: #f44336;
}

/* loading style */

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.loading-circle {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #aa5647;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1000px) {
  .about-section {
    flex-direction: column;
  }
  .contact-form div,
  .contact-form button {
    width: 100%;
  }
  h1 {
    font-size: 62px !important;
  }
  .hero-container p {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 600px) {
  .p-20 {
    padding: 2rem !important;
  }
  .px-20 {
    padding: 2rem !important;
  }
  .contact-form {
    padding: 2rem;
  }
  h1 {
    font-size: 42px !important;
  }
  .hero-container p {
    font-size: 16px !important;
  }
}
