.why-us-container .content-container:nth-child(2) .content {
  margin-left: 50%;
  padding-left: 2.5rem;
  padding-right: 0;
}

.why-us-container .content-container:nth-child(2) .content-image-container {
  left: 0 !important;
}

@media only screen and (max-width: 1000px) {
  .content-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 2.5rem;
  }

  .content-container .content {
    width: 100%;
    padding-left: 0;
  }
  .content-container div {
    position: relative;
    margin-left: 0 !important;
    padding-left: 0 !important;
    width: 100% !important;
  }
  .content-image-container {
    height: 500px;
  }
}
